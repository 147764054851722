// Config values
$platform-name: "havenhouse";
$assets-path: "../assets/";

// Underline mixin
@mixin underline ($colour) {
  &:after {
      content: '';
      display: block;
      width: 2.75em;
      height: 0.25em;
      background-color: $colour;
      margin-top: 0.6em;
      margin-bottom: 0.65em;
      border-radius: 2.25px;
  }
}

// Font Import - INTRO COND BLACK
@font-face {
  font-family: 'Intro Cond Black';
  src: url($assets-path + 'IntroCond-Black.woff2') format('woff2'),
       url($assets-path + 'IntroCond-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// Colour presets
$_purple: #6A357B;
$_blue: #004899;
$_orange: #D68100; // #F39200;
$_magenta: #E01783;
$_green: #0F892E; // #12A537;
$_aqua: #008482; // #009491;
$_red: #E30513;

$_black: #06131E;
$_grey: #A3AAAE;


// Colours
$brand-primary: $_blue;
$brand-primary-text-contrast-override: null;
$brand-secondary: $_green;
$brand-secondary-text-contrast-override: null;

$donate-colour: $_purple;

// Create greyscale
$black: #000;
$grey-dark: $_black;
$grey: #464A4C;
$grey-light: #636C72;
$grey-lighter: #ECEEEF;
$grey-lightest: #F0F0F0;
$white: #FFF;

// Layout
$container-max-width-sm: 900px;
$container-max-width-md: 1230px;
$container-max-width-lg: 1440px;

$container-max-width: $container-max-width-md;
$gap-width: 30px;
$site-bleed: 15px;

$border-radius: 6px;
$border-colour: $grey-lighter;
$box-shadow: none;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1060px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $_black;
$profile-pics-enabled: true;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "logo.svg";
$logo-width: 165px;
$logo-height: 150px;
$logo-absolute: true;
$logo-breakpoint: map-get($breakpoints, lg);
$logo-mobile: $logo;
$logo-mobile-width: $logo-width * 0.65;
$logo-mobile-height: $logo-height * 0.65;

// Typography
$font-family-base: 'Nunito', sans-serif;
$font-size-base: 1.025rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: 400;
$font-weight-bold: 900;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.4em;
$font-size-h2: 2.15em;
$font-size-h3: 1.9em;
$font-size-h4: 1.65em;
$font-size-h5: 1.4em;
$font-size-h6: 1.15em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'Intro Cond Black', Helvetica, sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: uppercase;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-decoration-colour: initial;
$link-hover-decoration: underline;
$link-hover-style: darken; // darken or lighten text links on hover

// Icon font
$icon-font-family: "FontAwesome"; // TODO: Add in alternatives here

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: none;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-text-transform: uppercase;
$btn-text-decoration: none;
$btn-line-height: 1.25;
$btn-letter-spacing: inherit;
$btn-hover-style: darken; // darken or lighten the button on hover
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour);

// Social icons
$social-icons-background-colour: $_purple;
$social-icons-colour: text-contrast($social-icons-background-colour);
$social-icons-hover-colour: $social-icons-colour;
$social-icons-width: 37.5px;
$social-icons-height: $social-icons-width;
$social-icons-font-size: 1.1rem;
$social-icons-gap: 2.5px;
$social-icons-border-radius: $social-icons-width;
$social-icons-box-shadow: $btn-box-shadow;

// Header specific
$social-icons-header-background-colour: $_green;
$social-icons-header-colour: text-contrast($social-icons-header-background-colour);
$social-icons-header-hover-colour: text-contrast($social-icons-header-background-colour);

// Footer specific social icons
$social-icons-footer-background-colour: $social-icons-background-colour; // A colour, transparent or 'brand'
$social-icons-footer-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-footer-hover-colour: $social-icons-hover-colour; // A colour, or 'brand'

// Share this page
$share-enabled: true;
$share-text-align: right;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: none;
$card-box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 2px 3px 0px;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $brand-primary;
$card-image-padding: 0;
$card-image-border-radius: 0.001px;
$card-details-padding: $spacer $spacer #{$spacer * 1.5};
$card-details-background-colour: $white;
$card-details-background-gradient: none; // none or CSS gradient
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: text-contrast($card-details-background-colour);
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.05);
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: 0.5rem $spacer;
$card-min-width: 270px;

// Card basic
$card-basic-image-position: above; // above, below

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom; // top, center, bottom
$card-text-overlay-details-position-x: left; // left, center, right
$card-text-overlay-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card text over
$card-text-over-image-vertical-text-position: center; // top, center or bottom
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $_black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true; // Do you want the image to scale to 100% height of the overall card?
$card-side-by-side-vertical-text-position: top; // top, center or bottom
$card-side-by-side-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 3px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.05;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient; // none or CSS gradient
$card-hover-heading-colour: $card-heading-colour;
$card-hover-summary-colour: text-contrast($card-hover-details-background-colour);

// Event cards
$event-card-floating-date: false;
$event-card-floating-date-background-colour: darken($_green, 10%);
$event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
$event-card-floating-date-border-radius: #{$border-radius / 2};
$event-card-floating-date-margin-y: #{$spacer * 0.5};
$event-card-floating-date-margin-x: #{$spacer * -0.5};

// Global alert
$global-alert-background-colour: $_orange;
$global-alert-colour: $white;

// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%; // $container-max-width
// $menu-admin-background-colour: $grey-lighter;
// $menu-admin-background-gradient: none; // none or CSS gradient
// $menu-admin-contents-max-width: $container-max-width; // 100%
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right; // left, center, right
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: 0px;

// Header
$header-background-colour: $white;
// $header-background-gradient: none;
$header-box-shadow: 0 0 10px rgba($_black, 0.1);

$header-absolute: false;
// $header-absolute-background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0)); // Colour or gradient
// $header-absolute-breakpoint: map-get($breakpoints, sm); // When do you want to abandon the absolute header and return to solid?

// Header content
$header-content-max-width: $container-max-width-md;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: $spacer;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

$header-content-desktop-padding-top: #{$spacer * 2.25};
$header-content-desktop-padding-bottom: #{$spacer * 2.25};

// Tagline
$tagline-enabled: false;
// $tagline-font-family: $headings-font-family;
// $tagline-font-weight: $font-weight-bold;
// $tagline-font-size: $font-size-h5;
// $tagline-colour: $headings-colour;
// $tagline-above-cta-breakpoint: 0;
// $tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: text-contrast($cta-btn-background-colour);
$cta-btn-gap: 7.5px;
$cta-btn-breakpoint: 0;
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: 15px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 180px;
$header-search-input-height: $social-icons-width;
$header-search-input-margin-right: -$social-icons-width;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $input-placeholder-colour;
$header-search-input-border-radius: $border-radius;
$header-search-input-border-colour: $border-colour;
$header-search-input-border-width: 1px 1px 1px 1px;
$header-search-input-background-colour: $grey-lightest;
$header-search-input-colour: text-contrast($header-search-input-background-colour);
$header-search-input-focus-background-colour: $grey-lightest;
$header-search-input-focus-colour: text-contrast($header-search-input-focus-background-colour);

// Search - button
$header-search-button-width: $social-icons-width;
$header-search-button-height: $social-icons-width;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $_blue;
$header-search-button-border-radius: none;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: -$social-icons-width;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-colour: $header-search-input-border-colour;
$nav-search-input-border-width: 0;
$nav-search-input-background-colour: $header-search-input-background-colour;
$nav-search-button-background-colour: transparent;
$nav-search-button-icon-colour: $header-search-button-icon-colour;

// Social icons -- Colours set above (search for '$social-icons-header')
$header-social-icons-margin-right: 15px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);

// Sticky header
$header-sticky-enabled: false;
// $header-sticky-background-colour: $header-background-colour;
// $header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
// $header-sticky-logo: $logo;
// $header-sticky-logo-retina: $header-sticky-logo;
// $header-sticky-logo-width: $logo-width * 0.5;
// $header-sticky-logo-height: $logo-height * 0.5;
// $header-sticky-menu-admin-enabled: false;
// $header-sticky-search-enabled: false;
// $header-sticky-social-icons-enabled: false;
// $header-sticky-cta-btns-enabled: true;
// $header-sticky-header-content-margin-top: $header-content-margin-top * 0.75;
// $header-sticky-header-content-margin-bottom: $header-content-margin-bottom * 0.75;
// $header-sticky-nav-margin-bottom: 0;

// Navigation
$nav-type: normal; // normal, flyout, fullscreen, mega
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $header-background-colour;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 10px 20px 12.5px;
$nav-top-level-item-colour: $_blue;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-background-colour: $_blue;
$nav-top-level-item-hover-colour: text-contrast($nav-top-level-item-hover-background-colour);
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
$nav-top-level-first-item-home-icon: false;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $nav-top-level-item-hover-background-colour;
$nav-submenu-box-shadow: $card-box-shadow;
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: text-contrast($nav-submenu-background-colour);
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-normal;
$nav-submenu-item-hover-background-colour: rgba($_black, 0.15);
$nav-submenu-item-hover-colour: text-contrast($nav-submenu-background-colour);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: text-contrast($nav-submenu-background-colour);

// Burger button
$burger-btn-background-colour: $_blue;
$burger-btn-text-colour: text-contrast($burger-btn-background-colour);
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: 560px; // What viewport width do you want to remove the 'menu' text from the burger button?
$burger-btn-bar-breakpoint: null; // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: calc(#{$container-max-width} - #{$site-bleed * 2});
$nav-normal-align-items: right;
$nav-normal-border-radius: $border-radius;
$nav-normal-mobile-background-colour: $brand-primary;
$nav-normal-mobile-top-level-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-submenu-background-colour: rgba($_black, 0.08);
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour);

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: false;
// $nav-parent-clickable-chevron-background-colour: rgba(#fff, 0.75);
// $nav-parent-clickable-chevron-colour: $nav-normal-mobile-background-colour;
// $nav-parent-clickable-chevron-icon-type: "chevron"; // can take "chevron" or "plus"
// $nav-parent-clickable-chevron-active-background-colour: #fff;
// $nav-parent-clickable-chevron-active-colour: $nav-parent-clickable-chevron-colour;

// Nav - Mega menu
// $nav-mega-max-width: $container-max-width;
// $nav-mega-background-colour: $grey-lightest;
// $nav-mega-margin-top: $spacer;
// $nav-mega-border-radius: $border-radius;
// $nav-mega-box-shadow: none;
// $nav-mega-submenu-per-row: 3;
// $nav-mega-submenu-background-colour: $white;
// $nav-mega-submenu-item-colour: text-contrast($nav-mega-submenu-background-colour);
// $nav-mega-submenu-item-hover-colour: $nav-mega-submenu-item-colour;
// $nav-mega-submenu-item-hover-background-colour: rgba($black, 0.1);
// $nav-mega-submenu-border-radius: $border-radius;
// $nav-mega-submenu-heading-background-colour: $brand-primary;
// $nav-mega-submenu-heading-colour: text-contrast($nav-mega-submenu-heading-background-colour);
// $nav-mega-submenu-submenu-box-shadow: none;
// $nav-mega-submenu-heading-hover-background-colour: darken($nav-mega-submenu-heading-background-colour, 10%);
// $nav-mega-submenu-heading-hover-colour: $nav-mega-submenu-heading-colour;

// Specifically when no banner present and you have gone for $header-absolute: true
// $no-banner-header-background-colour: $header-background-colour;
// $no-banner-logo: $logo;
// $no-banner-logo-retina: $no-banner-logo;
// $no-banner-tagline-colour: $tagline-colour;
// $no-banner-social-icons-header-background-colour: $social-icons-header-background-colour; // A colour, transparent, or 'brand'
// $no-banner-social-icons-header-colour: $social-icons-header-colour; // A colour, or 'brand'
// $no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour; // A colour, or 'brand'
// $no-banner-nav-background-colour: $nav-background-colour;
// $no-banner-nav-top-level-item-colour: $nav-top-level-item-colour;
// $no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $no-banner-header-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $no-banner-header-search-input-border-colour: $header-search-input-border-colour;
// $no-banner-header-search-input-background-colour: $header-search-input-background-colour;
// $no-banner-header-search-button-background-colour: $header-search-button-background-colour;
// $no-banner-header-search-button-icon-colour: $header-search-button-icon-colour;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: null;
$carousel-contents-max-width: $container-max-width-lg; 
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
$carousel-details-background-colour: $_green;
$carousel-details-box-shadow: $box-shadow;
$carousel-details-max-width: 550px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 1.5;
$carousel-details-margin-y: #{$spacer * -3};
$carousel-details-margin-x: $site-bleed;
$carousel-details-border-radius: $border-radius;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: text-contrast($carousel-details-background-colour);
$carousel-heading-font-size: $font-size-h4;
$carousel-summary-enabled: true;
$carousel-summary-colour: text-contrast($carousel-details-background-colour);
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-enabled: false;
// $carousel-read-more-background-colour: $btn-background-colour;
// $carousel-read-more-border-colour: $btn-border-colour;
// $carousel-read-more-colour: $btn-text-colour;
// $carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;
$carousel-controls-button-size: $social-icons-width;
$carousel-controls-button-gap: 2.5px;
$carousel-controls-button-background-colour: $_orange;
$carousel-controls-button-border-radius: $border-radius;
$carousel-controls-button-box-shadow: $box-shadow;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-margin-y: $site-bleed;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: $social-icons-font-size;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
// $carousel-buttons-type: dots; // dots or tabs

// Carousel buttons dots
// $carousel-dots-position-y: bottom;
// $carousel-dots-position-x: right;
// $carousel-dots-padding: $site-bleed;
// $carousel-dots-colour: $white;
// $carousel-dots-opacity: 0.5;
// $carousel-dots-active-colour: $_orange;

// Carousel buttons tabs
// $carousel-tabs-font-size: $font-size-h3;
// $carousel-tabs-background-colour: $grey-lighter;
// $carousel-tabs-colour: $brand-primary;
// $carousel-tabs-active-background-colour: $brand-primary;
// $carousel-tabs-active-colour: text-contrast($carousel-tabs-active-background-colour);
// $carousel-tabs-active-triangle-enabled: true;
// $carousel-tabs-text-align: left;
// $carousel-tabs-font-weight: $btn-font-weight;
// $carousel-tabs-text-transform: $btn-text-transform;
// $carousel-tabs-padding-x: $spacer;
// $carousel-tabs-padding-y: $spacer;
// $carousel-tabs-separator-width: 0px; // Width of border between tabs
// $carousel-tabs-separator-colour: $border-colour;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%; // What percentage do you want the play button positioned vertically?
$carousel-video-btn-position-x: 50%; // What percentage do you want the play button positioned laterally?

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
// $carousel-scroll-btn-size: $carousel-controls-button-size;
// $carousel-scroll-btn-background-colour: $btn-background-colour;
// $carousel-scroll-btn-border-radius: $border-radius;
// $carousel-scroll-btn-box-shadow: $box-shadow;
// $carousel-scroll-btn-icon-colour: $btn-text-colour;
// $carousel-scroll-btn-icon-font-size: 1em;
// // $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
// $carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
// $carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
$carousel-yt-background-size: "cover"; // 'cover' or 'contain'
$carousel-yt-cover-padding-bottom: 56.25%; // Play with this to insure you get proper coverage. This is a dark art, see https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed. I'm giving you control here to play around with it.

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: text-contrast($_green);
$carousel-mobile-summary-colour: text-contrast($_green);

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 200px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $grey-lightest;
// $home-intro-background-gradient: none;
// $home-intro-background-image: null;
// $home-intro-background-image-opacity: 0.5;
$home-intro-box-shadow: none;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 6;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: left;
$home-intro-text-transform: none;
$home-intro-font-family: $font-family-base; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-base;
$home-intro-font-weight: $font-weight-base;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: none;
$home-intro-mobile-padding-y: $home-intro-padding-y * .5;
$home-intro-mobile-font-size: $home-intro-font-size;

// Home headlines intro
$home-headlines-intro-enabled: false;
// $home-headlines-intro-snippet-title-enabled: false;
// $home-headlines-intro-max-width: 100%;
// $home-headlines-intro-content-max-width: $container-max-width;
// $home-headlines-intro-background-colour: $white;
// $home-headlines-intro-padding-top: $spacer * 5;
// $home-headlines-intro-padding-bottom: 0;
// $home-headlines-intro-margin-top: 0;
// $home-headlines-intro-margin-bottom: 0;
// $home-headlines-intro-text-align: center;
// $home-headlines-intro-text-transform: $headings-text-transform;
// $home-headlines-intro-font-family: $headings-font-family;
// $home-headlines-intro-font-size: $font-size-h2;
// $home-headlines-intro-colour: text-contrast($home-headlines-intro-background-colour);

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $white;
$home-features-padding-top: $spacer * 1.5;
$home-features-padding-bottom: $spacer * 1.5;
$home-features-cards-entry-animation-prefab: 2;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 4;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 3;
$impact-stats-background-colour: $_blue;
$impact-stats-background-gradient: none;
$impact-stats-background-image: 'swirl-white.svg';
$impact-stats-background-image-opacity: 0.05;
$impact-stats-background-image-size: 45%;
$impact-stats-border-radius: 0;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: left;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, lg);

$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h3;
$impact-stats-heading-text-align: $impact-stats-text-align;
$impact-stats-heading-colour: text-contrast($impact-stats-background-colour);
$impact-stats-heading-margin-bottom: calc(#{$spacer * 1.5} - #{$gap-width / 2});
$impact-stats-heading-text-transform: $headings-text-transform;

// Individual stat
$impact-stat-min-width: 180px; 
$impact-stat-direction: column;
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour;
$impact-stat-border-width: 0 0 0 0;

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4.25em;

// Summary
$impact-stats-summary-colour: text-contrast($impact-stats-background-colour);
$impact-stats-summary-font-size: $font-size-base;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: $spacer;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 3;
$home-feeds-padding-bottom: $spacer * 2;
$home-feeds-cards-entry-animation-prefab: 2;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 4;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h4;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left;
$feeds-title-margin-bottom: #{$spacer * 1.5};
$feeds-title-text-transform: $headings-text-transform;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Twitter feed
$home-feed-twitter-enabled: false;
// $home-feed-twitter-card-gap-width: $card-gap-width;
// $home-feed-twitter-card-border: $card-border;
// $home-feed-twitter-card-border-radius: $card-border-radius;
// $home-feed-twitter-card-box-shadow: $card-box-shadow;
// $home-feed-twitter-card-text-align: $card-text-align;
// $home-feed-twitter-card-padding: $card-details-padding;
// $home-feed-twitter-card-background-colour: $card-details-background-colour;
// $home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour);
// $home-feed-twitter-card-min-width: $card-min-width;

// Footer
$footer-boxes-count: 3;
$footer-prefab: 4;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 3;
$footer-background-colour: $grey-lightest;
$footer-background-gradient: none; // none or CSS gradient
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: text-contrast($footer-background-colour);
$footer-link-decoration: none;
$footer-link-hover-colour: darken($footer-link-colour, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: center;
$footer-font-size: $font-size-base;
$footer-headings-font-size: inherit;
$footer-headings-text-transform: $headings-text-transform;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour:  $_blue;
// $newsletter-background-gradient: none;
$newsletter-colour: text-contrast($newsletter-background-colour);
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h5;
$newsletter-heading-text-align: center;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 1.5;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px;
$newsletter-button-background-colour: $white;
$newsletter-button-colour: $newsletter-background-colour;
$newsletter-button-border-colour: transparent;
$newsletter-use-placeholders: false;
$newsletter-required-label-enabled: false;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 3;
$newsletter-hero-contents-max-width: $container-max-width;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 1.5;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: false;
$donors-list-profile-pics-enabled: false;

// Posts
$post-content-max-width: $container-max-width;
$post-body-background-colour: transparent;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 6;
$header-text-margin-bottom: $spacer * 3;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $body-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left; // left, center or right
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * 0.8; // <= $breakpoints.md
$page-title-sm-font-size: $page-title-font-size * 0.6; // <= $breakpoints.sm

// Page title over banner
$page-title-over-banner-enabled: false;
// $page-title-over-banner-breakpoint: map-get($breakpoints, md);
// $page-title-over-banner-background-colour: $brand-primary; // Add a background colour so that title is always legible as it appears over a photo
// $page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour);
// $page-title-over-banner-breadcrumb-enabled: true; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird

// Sidebar
$sidebar-enabled: true;
$sidebar-layout: adjacent;
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 5;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;
$sidebar-border-radius: none;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 7;
$associated-list-heading-font-size: $font-size-large;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-heading-colour: text-contrast($_blue);
$associated-item-headings-text-transform: none;
$associated-item-summary-enabled: false;
$associated-item-border: true;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width; // Either a px value or 100%. Adjust this value to dictate how many listing items you want in a single row

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false; // Opt out of showing breadcrumbs on listing pages, if breadcrumbs are enabled
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align; // left, center or right

// Blockquote
$blockquote-max-width: $post-content-max-width; // Either a px value or 100%
$blockquote-background-colour: $_purple;
$blockquote-background-gradient: none; // none or CSS gradient
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 1.5;
$blockquote-padding: $spacer * 1.5;
$blockquote-text-align: left;
$blockquote-font-size: $font-size-large;
$blockquote-font-family: $font-family-base;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: text-contrast($blockquote-background-colour);
$blockquote-box-shadow: $card-box-shadow;

// Fundraising
$fundraising-enabled: true; // Quickly turn off fundraising features

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: $profile-pics-enabled;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: true;
$donation-form-default-complete-message-enabled: true;

// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $donate-colour;
$quick-giving-background-gradient: none;
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: transparent;
$quick-giving-donation-amount-figure-colour: text-contrast($quick-giving-background-colour);
$quick-giving-donation-amount-border-width: 3px;
$quick-giving-donation-amount-border-colour: text-contrast($quick-giving-background-colour);
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $donate-colour;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: calc(#{$container-max-width} - #{$card-gap-width});
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: $spacer * 3;
$home-quick-giving-padding-y: $spacer * 3;
$home-quick-giving-background-colour: $donate-colour;
$home-quick-giving-background-gradient: $quick-giving-background-gradient;
$home-quick-giving-background-image: null;
$home-quick-giving-background-image-opacity: 0.5;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-figure-colour: text-contrast($home-quick-giving-background-colour);
$home-quick-giving-donation-amount-border-width: 3px;
$home-quick-giving-donation-amount-border-colour: text-contrast($home-quick-giving-background-colour);
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
$home-quick-giving-donate-btn-background-colour: transparent;
$home-quick-giving-donate-btn-colour: text-contrast($home-quick-giving-background-colour);
$home-quick-giving-donate-btn-border-colour: text-contrast($home-quick-giving-background-colour);
$home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
$home-quick-giving-colour: text-contrast($home-quick-giving-background-colour);
$home-quick-giving-heading-colour: tect-contrast($home-quick-giving-background-colour);
$home-quick-giving-heading-font-size: $font-size-h3;

// Adjacent homepage quick giving
// $home-quick-giving-type-adjacent-image-side: left; // If type is adjacent, which side do you want the image

// Overlay homepage quick giving
// $home-quick-giving-type-overlay-items-side: left; // If type is overlay, which side do you want the copy and donation items?
// $home-quick-giving-type-overlay-gradient: linear-gradient(to left, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.75) 100%);

// Button actions
$button-actions-padding: 0;
$button-actions-background-colour: transparent;
$button-actions-align-buttons: left; // left, center, right

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: false;
$comment-add-video-enabled: false;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: $context-container-border-radius;
$blog-details-profile-pic-enabled: $profile-pics-enabled;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $context-container-background-colour;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $btn-background-colour;
$faq-item-btn-chevron-colour: $btn-text-colour;
// $faq-item-hover-btn-background-colour: button-hover-state($faq-item-btn-background-colour);
// $faq-item-hover-btn-chevron-colour: text-contrast($faq-item-hover-btn-background-colour);

// In Memory
$listing-inmem-masonry-layout: true;

// Shop
$shop-enabled: true;
$basket-link-only-on-shop-pages: true;

// Departments list
$department-list-width: $sidebar-adjacent-width;
$department-list-background-colour: $context-container-background-colour;
$department-list-padding: $context-container-padding;
$department-list-border-radius: $context-container-border-radius;

// Product-price
$product-price-colour: $brand-secondary;
$product-price-font-size: $font-size-h4;

// Product post
$product-post-content-max-width: $post-content-max-width;

// Product rating
$product-rating-enabled: true;

// Subsite
$subsite-enabled: false;
// $subsite-hide-main-header: false;
// $subsite-hide-main-tagline: false;
// $subsite-hide-header-search: false;
// $subsite-hide-main-cta: false;
// $subsite-content-max-width: $container-max-width;
// $subsite-title-font-size: $page-title-font-size;

// // Subsite logo
// $subsite-logo: "logo.svg"; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
// $subsite-logo-retina: $subsite-logo;
// $subsite-logo-width: 120px;
// $subsite-logo-height: 120px;
// $subsite-logo-margin: $header-content-margin-top 0 0;

// // Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint;
// $subsite-nav-max-width: $nav-normal-max-width;
// $subsite-nav-margin-top: $spacer;
// $subsite-nav-margin-bottom: $spacer;
// $subsite-nav-background-colour: $nav-background-colour;
// $subsite-nav-contents-max-width: $container-max-width;
// $subsite-nav-align-items: $nav-normal-align-items; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour;

// $subsite-nav-submenu-width: $nav-submenu-width;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour;

// Cookie consent
$cookie-consent-position: bottom; // Bottom or top
$cookie-consent-background-colour: $black; // You can use rgba here for opacity
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-font-family: initial;
$cookie-consent-btn-background-colour: $btn-background-colour;
$cookie-consent-btn-text-colour: $btn-text-colour;

// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;

// Slick
$slick-enabled: false;
