// Home features - Overall layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );

  // Home feature - Specific card layout
  .homeBox1 {
    @include card (
      $card-details-background-colour: $_orange,
      $card-hover-details-background-colour: darken($_orange, 5%),
      $card-heading-colour: $white,
      $card-hover-heading-colour: $white,
      $card-summary-colour: $white,
      $card-hover-summary-colour: $white,
    );
    @include card-basic (
      $prefab: 3
    );

    // Heading underline
    h2 {
      @include underline (rgba($black, 0.25));
    }
  }

  // Home feature - Specific card layout
  .homeBox2 {
    @include card (
      $card-details-background-colour: $_purple,
      $card-hover-details-background-colour: darken($_purple, 5%),
      $card-heading-colour: text-contrast($_purple),
      $card-hover-heading-colour: text-contrast(darken($_purple, 5%)),
      $card-summary-colour: text-contrast($_purple),
      $card-hover-summary-colour: text-contrast(darken($_purple, 5%)),
    );
    @include card-basic (
      $prefab: 3
    );

    // Heading underline
    h2 {
      @include underline (rgba($black, 0.25));
    }
  }

  // Home feature - Specific card layout
  .homeBox3 {
    @include card (
      $card-details-background-colour: $_green,
      $card-hover-details-background-colour: darken($_green, 5%),
      $card-heading-colour: text-contrast($_green),
      $card-hover-heading-colour: text-contrast(darken($_green, 5%)),
      $card-summary-colour: text-contrast($_green),
      $card-hover-summary-colour: text-contrast(darken($_green, 5%)),
    );
    @include card-basic (
      $prefab: 3
    );

    // Heading underline
    h2 {
      @include underline (rgba($black, 0.25));
    }
  }
}



// Home feeds - Overall layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);



// Home feed - Card layout
.homeFeed.homeFeedevents .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );

  // Home feed - Card layout
  .feedItem {
    @include card (
      $card-details-background-colour: $_green,
      $card-hover-details-background-colour: darken($_green, 5%),
      $card-heading-colour: text-contrast($_green),
      $card-hover-heading-colour: text-contrast(darken($_green, 5%)),
      $card-summary-colour: text-contrast($_green),
      $card-hover-summary-colour: text-contrast(darken($_green, 5%)),
      $card-footer-padding: 0
    );
    @include card-basic (
      $prefab: 3
    );

    // Heading underline
    h3 {
      @include underline (rgba($black, 0.25));
    }
  }
}



// Home feed - Card layout
.homeFeed.homeFeednews .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 4
  );

  // Home feed - Card layout
  .feedItem {
    @include card (
      $card-details-background-colour: $_orange,
      $card-hover-details-background-colour: darken($_orange, 5%),
      $card-heading-colour: $white,
      $card-hover-heading-colour: $white,
      $card-summary-colour: $white,
      $card-hover-summary-colour: $white,
    );
    @include card-basic (
      $prefab: 3
    );

    // Heading underline
    h3 {
      @include underline (rgba($black, 0.25));
    }
  }
}



// Listed posts
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):not(.listedAppeal):not(.listedEvent):not(.module-news):not(.module-blog) {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: darken($grey-lightest, 5%),
    $card-heading-colour: $body-colour,
    $card-hover-heading-colour: $body-colour,
    $card-summary-colour: $body-colour,
    $card-hover-summary-colour: $body-colour,
  );
  @include card-basic;

  // Heading underline
  h2 {
    @include underline ($_blue);   
  }
}



// Listed Event
.listedEvent {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: darken($grey-lightest, 5%),
    $card-heading-colour: $body-colour,
    $card-hover-heading-colour: $body-colour,
    $card-summary-colour: $body-colour,
    $card-hover-summary-colour: $body-colour,
  );
  @include card-basic;

  // Heading underline
  h2 {
    @include underline ($_green);   
  }
}


// Listed News
.module-news {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: darken($grey-lightest, 5%),
    $card-heading-colour: $body-colour,
    $card-hover-heading-colour: $body-colour,
    $card-summary-colour: $body-colour,
    $card-hover-summary-colour: $body-colour,
  );
  @include card-basic;

  // Heading underline
  h2 {
    @include underline ($_orange);   
  }
}


// Listed News
.module-blog {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: darken($grey-lightest, 5%),
    $card-heading-colour: $body-colour,
    $card-hover-heading-colour: $body-colour,
    $card-summary-colour: $body-colour,
    $card-hover-summary-colour: $body-colour,
  );
  @include card-basic;

  // Heading underline
  h2 {
    @include underline ($_blue);   
  }
}


// Listed appeals + Listed products
.listedAppeal, .listedProduct {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: darken($grey-lightest, 5%),
    $card-heading-colour: $body-colour,
    $card-hover-heading-colour: $body-colour,
    $card-summary-colour: $body-colour,
    $card-hover-summary-colour: $body-colour,
  );
  @include card-basic;

  // Heading underline
  h2 {
    @include underline ($_purple);   
  }
}