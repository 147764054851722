// Bespoke appeal setup
@include appeal-field('sendastar', 'christmas');
@include appeal-field('together-for-christmas', 'christmas');

// Bespoke gingerbread visual appeal
//@include appeal-field('gingerbread-christmas', 'christmas');
@include appeal-field($body-class: 'gingerbread-christmas', 
  $background-colour: white,
  $background-image: 'bg-gingerbread2.png',
  $post-text-colour: #fff,
  $comment-text-colour: #000,
  $icon-image: 'candy-striped-red.svg',
  $icon-size: 80px,
  //$icon-animation: glitter 4s linear 0s infinite normal,
  $appeal-content-colour: $brand-secondary
);

// Global alert banner - Format
.GlobalAlert {
    font-weight: $font-weight-bold;
    background-color: $_red;
}


// Header Desktop - Overall
@media (min-width: map-get($breakpoints, lg) + 1px) {
    .pageHeader .headerContent {
        padding-top: $header-content-desktop-padding-top;
        padding-bottom: $header-content-desktop-padding-bottom;
    }
    .mainLogo {
        top: #{$spacer * 0.75};
        z-index: 4;
    }
    .menuMain {
        position: relative;
        z-index: 3;
    }
    .menuMain .topLevel li.level1 > a {
        border-radius: $border-radius $border-radius 0 0;
    }
}


// Carousel - Desktop overall
@media (min-width: #{$carousel-breakpoint + 1px}) {
    .carouselSlide {
        overflow: visible;
        z-index: 2;
    }
    .carouselControls {
        z-index: 3;
    }
  
    .carouselSlideDetail {
        box-shadow: $card-box-shadow;
    }
  }
  
  
// Carousel - Mobile overall
@media (max-width: $carousel-breakpoint) {
    .carousel {
        background-color: $home-intro-background-colour;
    }
    .carouselDetailWrapper {
        background-color: transparent;
    }
    .carouselSlideDetail {
        max-width: calc(100vw - #{$site-bleed * 2});
        margin: -$spacer auto 0;
        background-color: $carousel-details-background-colour;
        border-radius: $border-radius;
        z-index: 1;
    }
    .carouselControls {
        justify-content: space-between;
        align-items: flex-start;
        padding-top: #{$site-bleed * 2 };
        padding-left: #{$site-bleed / 2 };
        padding-right: #{$site-bleed / 2 };
    }
}


// Carousel heading - Underline
.carouselSlideHeading {
    @include underline (rgba($black, 0.25));
}


// Home intro - Overall format
.homeIntro span {
    font-size: $card-heading-font-size;
    font-family: $headings-font-family;
    line-height: $headings-line-height;
    text-transform: $headings-text-transform;
    @include underline ($_blue);   
}


// Home intro - Margin gap background
@media (min-width: map-get($breakpoints, lg) + 1px) {
    .homeIntroWrapper {
        padding-top: calc(#{$home-intro-padding-y} + #{$spacer * 2});
        z-index: 1;
    }
}


// Homepage stats - Background
.homefeaturecategory-homeboximpactstats:before {
    background-position: top #{$spacer * -2} right #{$spacer * -2};
    @media (max-width: $impact-stats-breakpoint) {
        background-size: auto 100%;
        background-position: center;
    }
}


// Homepage stats - Heading format + Undeline
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
    padding-left: #{$impact-stat-margin / 2};
    padding-right: #{$impact-stat-margin / 2};
    @include underline (rgba($black, 0.25));
}


// Homepage stats - Icon format
.homefeaturecategory-homeboximpactstats td.containsIcon {
    order: -1;
    margin-bottom: #{$impact-stats-summary-margin-top * 1.5};
    img {
        max-height: 100px;
    }
}


// Homepage quick giving panel - Overall
.homefeaturecategory-homeboxquickgiving {
    width: calc(100% - #{$site-bleed * 2});
    box-shadow: $card-box-shadow;
    border-radius: $border-radius;
}


// Quick Giving Panel - Amounts format
.quickGivingPanel .donationAmount {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
}


// Quick Giving Panel - Mobile amounts layout
@media (max-width: map-get($breakpoints, md)) {
    .homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount,
    .homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount.donationAmountOther,
    .quickGivingPanel .formQuestion.donationAmount {
        display: inline-block;
        width: calc(50% - 10px);
        margin: 5px;
    }
}


// Quick Giving Panel - Button format
.quickGivingPanel .donateBtn {
    box-shadow: inset 0 0 0 3px $home-quick-giving-donate-btn-colour;
    font-weight: $font-weight-bold;
    &:hover { color: $donate-colour !important; }
}


// Feeds desktop - Layout
// @media (min-width: map-get($breakpoints, md) + 1px) {
//     .homeFeed {
//         margin-right: 0;
//     }
//     .homeFeed.homeFeedevents {
//         flex: 2;
//     }
// }


// Footer newsletter
.footerBox[class*='ewsletter'] {
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    > * {
        max-width: calc(#{$newsletter-hero-contents-max-width} - #{$site-bleed * 2});
        margin-left: auto;
        margin-right: auto;
    }
}


// Footer logos
.footerBox[class*='ogos'] {
    position: relative;
    width: 100%;
    margin: $footer-padding-y 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
        margin: $site-bleed;
        max-height: 80px;
        object-fit: contain;
    }
}


// Footer content - Layout
.footerBox[class*='inks'] {
    ul {
        display: flex;
        gap: $spacer;
        justify-content: center;    
        margin-bottom: #{$spacer * 3};
        li:not(:last-of-type):after {
            content: '';
            display: inline-flex;
            margin-left: $spacer;
            height: 100%;
            width: 3px;
            border-radius: 0.5px;
            background-color: $_green;
            vertical-align: middle;
        }
    }
    p {
        font-weight: $font-weight-bold;
    }
}


// Card format - Overall
.homeFeatures .homeBox1, .homeFeatures .homeBox2, .homeFeatures .homeBox3,
.homeFeed.homeFeedevents .feedList .feedItem, .homeFeed.homeFeednews .feedList .feedItem {
    box-shadow: none !important;
    [class*="DetailsWrapper"]:not(.publishDetailsWrapper), [class^="listed"][class$="Text"] {
        position: relative;
        border-radius: $card-border-radius;
        box-shadow: $card-box-shadow;
        z-index: 1;
    }
    [class*="image-link"] {
        border-radius: #{$border-radius / 2};
    }
    &:hover [class*="DetailsWrapper"]:not(.publishDetailsWrapper), [class^="listed"][class$="Text"] {
        box-shadow: $card-hover-box-shadow;
        transform: translateY(#{$spacer * -1.25});
    }
}


// Card event - Floating date
.homeFeed.homeFeedevents .feedList .feedItem, .listedPost.listedEvent:not(.listedEventNoBanner) {
    overflow: visible;
    .postFooterWrapper {
        transform: none !important;
    }
    .associatedStartDate {
        position: absolute;
        margin: $event-card-floating-date-margin-y $event-card-floating-date-margin-x !important;
        top: 0;
        left: 0;
        opacity: 1 !important;
        background-color: $event-card-floating-date-background-colour;
        color: $event-card-floating-date-colour;
        padding: #{$spacer / 2};
        border-radius: $event-card-floating-date-border-radius;
        font-weight: $font-weight-bold;
    }
}


// Translate widget 🤮
.goog-te-menu-value {
    color: #000 !important;
}


// Page title - Underline
h1.title {
    @include underline ($_purple);
}


// Sidebar related - Overall
.associated:not(.associatedBox):not(.associatedAppeal) {
    padding: $context-container-padding;
    border-radius: $context-container-border-radius;
    background-color: $_blue;
    margin-top: #{$spacer * 1.5};
    overflow: hidden;
    box-shadow: $card-box-shadow;
    li { border-color: rgba($white, 0.25); }
    > h2 {
        color: $associated-item-heading-colour;
    }
    li h3 a {
        font-family: $font-family-base;
        font-weight: $font-weight-base;
    }
}


// Sidebar features - Overall
.associated.associatedBox, .associated.associatedAppeal {
    background-color: $grey-lightest;
    border-radius: $context-container-border-radius;
    padding-bottom: $context-container-padding;
    box-shadow: $card-box-shadow;
    h2 {
        margin-top: $spacer;
        a { color: $donate-colour; }
    }
    h2, .postSummary, .featureFooter {
        padding-right: $context-container-padding;
        padding-left: $context-container-padding;
    }
}


// Events details - Format
.eventWidgetsWrapper .eventDetails {
    background-color: $_green !important;
    border-radius: $context-container-border-radius;
    padding: $context-container-padding !important;
    box-shadow: $card-box-shadow;
    * { color: text-contrast($_green); }
}
.addToCalender {
    background-color: transparent;
    box-shadow: inset 0 0 0 3px $white;
    &:hover {
        background-color: $white;
        color: $_green;
    }
}


// Share wrapper - Format
.shareWrapper > strong {
    font-family: $headings-font-family;
    text-transform: $headings-text-transform;
}


// Back to top button - Format
.listFooter .button {
    background-color: $_orange;
    &:hover { background-color: darken($_orange, 10%); }
}


// Donation form - Format
.formQuestion.donationPeriod {
    margin-top: $spacer * 1.5;
    border-radius: $border-radius $border-radius 0 0;
}


// Custom styles
.Standard_1_Arial_11 {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
}

.floatLeft{
    float: left;
    margin: 20px 20px 20px 0px;
}

.floatRight {
    float: right;
    margin: 20px 0px 20px 20px
}

a.cta-button.blue, a.cta_Blue {
    @include button (
        $btn-background-colour: $_blue,
        $btn-text-colour: text-contrast($_blue)
    )
}

a.cta-button.purple {
    @include button (
        $btn-background-colour: $_purple,
        $btn-text-colour: text-contrast($_purple)
    )
}

a.cta-button.green {
    @include button (
        $btn-background-colour: $_green,
        $btn-text-colour: text-contrast($_green)
    )
}

a.cta-button.orange, a.cta_Orange {
    @include button (
        $btn-background-colour: $_orange,
        $btn-text-colour: $white
    )
}

blockquote.purple {
    background-color: $_purple;
    color: text-contrast($_purple);
}

blockquote.green {
    background-color: $_green;
    color: text-contrast($_green);
}

blockquote.blue {
    background-color: $_blue;
    color: text-contrast($_blue);
}

blockquote.orange {
    background-color: $_orange;
    color: $white
}


// Specific colour-code - NEWS
body.newsPostBody, body.newsList, body.newsBody {
    .carouselSlideDetail, h1.title:after, .associated:not(.associatedBox):not(.associatedAppeal), .shareList a {
        background-color: $_orange;
    }
    .shareList a:hover {
        background-color: darken($_orange, 10%); 
    }
}


// Specific colour-code - EVENTS
body.eventsPostBody, body.eventsBody, body.events {
    .carouselSlideDetail, h1.title:after, .associated:not(.associatedBox):not(.associatedAppeal), .shareList a {
        background-color: $_green;
    }
    .shareList a:hover {
        background-color: darken($_green, 10%); 
    }
}


// Specific colour-code - APPEALS
body.appealsPostBody, body.appealBody, body.appealsList, body.appealType {
    .carouselSlideDetail, h1.title:after, .associated:not(.associatedBox):not(.associatedAppeal), .shareList a {
        background-color: $_purple;
    }
    .shareList a:hover {
        background-color: darken($_purple, 10%); 
    }
}


// Specific colour-code - BLOGS
body.blogsBody, body.blogsList, body.blogsPostBody {
    .carouselSlideDetail, h1.title:after, .associated:not(.associatedBox):not(.associatedAppeal), .shareList a {
        background-color: $_blue;
    }
    .shareList a:hover {
        background-color: darken($_blue, 10%); 
    }
}


// Appeal tabs - Format
.tab.active { background-color: $_purple; }
.tab:not(.active) a { color: $_purple; }
.tab a { font-weight: $font-weight-bold; }



// Christmas apeal - Bespoke
body.appeal-together-for-christmas {
    .headerText {
        padding-top: 1rem;
    }
    .headerText p {
        font-size: $font-size-large;
    }
    .commentsList .commentContentWrapper {
        background-color: $_purple;
        &:before { color: $_purple; }
    }
    .appealComments {
        max-width: 1000px;
    }
    ul.commentsList--printed .commentDonationAmount {
        color: $donate-colour !important;
    }
    .commentsListWrapper .commentsList {
        margin: $spacer auto 400px;
    }
    .commentsList .commentDonation {
        &:before {
            transform-origin: top center;
            background-position: top center;
            animation: swing 4s linear 0s infinite normal;
            width: 55px;
            height: 55px;
        }
        &:nth-child(3n+3):before {
            background-image: url($assets-path + 'baubles-red.svg');
        }
        &:nth-child(3n+1):before {
            background-image: url($assets-path + 'baubles-blue.svg');
        }
        &:nth-child(3n+2):before {
            background-image: url($assets-path + 'baubles-green.svg');
        }
    }
}


body.appeal-gingerbread-christmas {

  @media (min-width: 769px) {
    .headerText {
      padding-right: 25%;
    }
  }

  .appealActionsWrapper .appealActions .button {
    background-color: $_red;
  }

  #bodyForm {
    background-position: center 0;
    background-repeat: no-repeat;
    @media (max-width: 1025px) {
      background-size: 2250px auto;
    }
    @media (max-width: 769px) {
      background-size: 1250px auto;
    }
  }

  .commentsListWrapper {
    padding-left: 40px;
    .commentsList--printed {
      padding-right: 40px;
    }
    .commentsList {
      max-width: 830px;
      min-height: 350px;
      margin: 100px auto 500px;
      li.commentDonation {
        transition: opacity 1s;
        position: relative;
        top: auto!important;
        left: auto!important;
        float: left;
        transition: opacity 1s;
        width: 80px;    
        margin: 0 -5px 0 0;
        &:before {
          height: 64px;
        }
        &:nth-of-type(20n+1) {
          margin-left: 40px;
        }
        &:nth-of-type(20n+11) {
          margin-left: 80px;
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .commentsListWrapper {
      padding-left: 30px;
      .commentsList--printed {
        padding-right: 30px;
      }
      .commentsList {
        max-width: 630px;
        min-height: 290px;
        margin: 100px auto 335px; 
        li.commentDonation {
          width: 60px;
          margin: 0 -3px 0 0;
          &:before {
            width: 60px;
            height: 48px;
            margin-left: -30px;
          }
          &:nth-of-type(20n+1) {
            margin-left: 30px;
          }
          &:nth-of-type(20n+11) {
            margin-left: 60px;
          }
        }  
      }
    }
  }

  @media (max-width: 769px) {
    .commentsListWrapper {
      padding-left: 15px;
      .commentsList--printed {
        padding-right: 15px;
      }
      .commentsList {
        max-width: 330px;
        min-height: 150px;
        margin: 100px auto 200px; 
        li.commentDonation {
          width: 30px;
          margin: 0 0 5px 0;
          &:before {
            width: 30px;
            height: 24px;
            margin-left: -15px;
          }  
          &:nth-of-type(20n+1) {
            margin-left: 15px;
          }
          &:nth-of-type(20n+11) {
            margin-left: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 400px) {
    .commentsListWrapper {
      padding-left: 0px;
      .commentsList {
        max-width: 273px;
        margin: 20px auto 200px; 
        li.commentDonation{ 
          width: 26px;
          margin: 0 0 5px 0;
          &:before {
            width: 26px;
            height: 21px;
            margin-left: 0;
          }
          &:nth-of-type(20n+1) {
            margin-left: 0px;
          }
          &:nth-of-type(20n+11) {
            margin-left: 13px;
          }
        }
      }
    }
  }

  .commentsList {
    li.commentDonation {
      &:nth-of-type(5n+1):before {
        background-image: url($assets-path + 'candy-striped-red.svg');
      }
      &:nth-of-type(5n+2):before {
        background-image: url($assets-path + 'candy-heart-red.svg');
      }      
      &:nth-of-type(5n+3):before {
          background-image: url($assets-path + 'candy-smartie-purple.svg');
      }
      &:nth-of-type(5n+4):before {
        background-image: url($assets-path + 'candy-striped-green.svg');
      }
      &:nth-of-type(5n+5):before {
        background-image: url($assets-path + 'candy-star-yellow.svg');
      }
    }
  }

}

// animation for stars and christmas
@keyframes swing {
    0% { transform: rotate(0deg); }
    33% { transform: rotate(-10deg); }
    66% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
}

// https://raisingit.atlassian.net/browse/DES-5598
#menu6c947c44-4c9f-4244-bf57-bfa159f53a03 a {
    color: $_red;
    &:hover {
        background-color: $_red;
        color: text-contrast($_red);
    }
}

// https://raisingit.atlassian.net/browse/DES-5517
.PostSystemCategory_hide-event-sold-out-message .eventBookingSoldOut {
    display: none;
}

// https://raisingit.atlassian.net/browse/CR-369
li#menue9d2877e-d7d7-4806-9c37-c01cadf26208 a {
  background: #e30513;
  color: white;
  &:hover {
    background-color: darken(#e30513, 5%);
  }
}